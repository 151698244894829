import React from "react";
import Splash from "./Splash";
import LatestEp from "./LatestEp";
import HeroImage from "./HeroImage";

import '../styles/TopSection.css';


const TopSection = props => {
  return (
    <section className="fullWidthBack">
      <div className="topSection">
        <div className="splashArea">
          <Splash />
        </div>
        <div className="latestArea">
          <LatestEp ep={props.ep}/>
        </div>
        <div className="heroArea">
          <HeroImage />
        </div>
      </div>
    </section>
  )
}

export default TopSection