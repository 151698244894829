export const VolumeUp = props => {
  return (
    <svg version="1.1" className="volup"  x="0px" y="0px"
	 viewBox="0 0 21 18.7" style={{width:props.width, height: props.height}}>
      <path d="M0,6.6c0.2-0.5,0.5-0.7,1-0.7c1,0,2,0,3,0c0.2,0,0.3,0,0.4-0.2c0,0,0.1-0.1,0.1-0.1C5.1,5,5.7,4.4,6.3,3.8
        C7,3,7.7,2.2,8.4,1.5c0.4-0.4,0.7-0.5,1-0.3C9.8,1.3,9.9,1.6,10,1.9C10,2,10,2,10,2.1c0,4.8,0,9.6,0,14.4c0,0.5-0.2,0.8-0.7,1
        c-0.2,0.1-0.5,0-0.6-0.2c-0.3-0.3-0.6-0.6-0.9-0.9c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.3-1.8-1.9c-0.5-0.5-1-1-1.5-1.5
        c-0.1-0.1-0.2-0.1-0.3-0.1c-1,0-2,0-3,0c-0.6,0-0.8-0.2-1-0.7C0,10.3,0,8.4,0,6.6z M20.2,5.3c-0.2-0.5-0.5-1-0.8-1.4
        c-0.5-0.9-1.2-1.6-1.9-2.3c-0.3-0.3-0.7-0.5-1-0.7c-0.4-0.3-0.8-0.5-1.3-0.7c-0.3-0.1-0.6-0.1-0.9,0.1C14,0.4,14,0.7,14,1
        c0.1,0.3,0.4,0.5,0.7,0.7c0.6,0.3,1.2,0.6,1.7,1.1c0.3,0.3,0.7,0.7,1,1c0.7,0.8,1.2,1.7,1.5,2.7c0.5,1.5,0.6,3,0.3,4.5
        c-0.3,1.7-1.1,3.2-2.3,4.4c-0.5,0.5-1.1,1-1.8,1.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3,0.4-0.2,0.7,0.1,1.1
        c0.2,0.3,0.6,0.3,1,0.2c0.2-0.1,0.4-0.2,0.5-0.3c0.6-0.3,1.2-0.6,1.6-1.1c0.3-0.3,0.7-0.7,1-1c0.6-0.6,1.1-1.4,1.5-2.2
        c0.8-1.5,1.1-3,1.1-4.6C21,7.9,20.8,6.6,20.2,5.3z M16.7,5.7c-0.4-0.7-1-1.3-1.6-1.8c-0.4-0.3-0.8-0.6-1.3-0.7c-0.4-0.2-0.9,0-1,0.4
        c-0.2,0.4-0.1,0.8,0.3,1.1c0.2,0.1,0.4,0.3,0.6,0.4c0.4,0.2,0.8,0.5,1.1,0.9c0.8,0.9,1.2,1.9,1.3,3.1c0.1,1.7-0.5,3.2-1.9,4.3
        c-0.3,0.2-0.7,0.4-1,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.4-0.2,0.8,0,1c0.2,0.3,0.6,0.4,1,0.2c0.2-0.1,0.5-0.2,0.7-0.4
        c0.5-0.3,0.9-0.6,1.3-1.1c0.6-0.6,1-1.3,1.4-2.1c0.4-0.8,0.5-1.7,0.5-3C17.8,8,17.4,6.8,16.7,5.7z M14.3,8c-0.3-0.8-0.9-1.4-1.7-1.7
        c-0.4-0.1-0.8,0-1,0.3c-0.2,0.3-0.2,0.7,0.1,1c0.2,0.2,0.4,0.3,0.6,0.5c0.9,0.5,0.8,2.1,0,2.6c-0.2,0.1-0.4,0.3-0.6,0.5
        c-0.3,0.3-0.2,0.8,0,1c0.2,0.3,0.6,0.4,1,0.2c0.6-0.3,1.1-0.8,1.5-1.4c0.3-0.5,0.4-1.1,0.4-1.8C14.6,8.9,14.4,8.4,14.3,8z"/>
    </svg>
  )
}


