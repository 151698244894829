import React, { useState, useEffect, useRef } from "react";
import { VolumeUp } from "./ui/VolumeUp";
import { VolumeDown } from "./ui/VolumeDown";

import '../styles/AudioPlayerVolume.css';

const AudioPlayerVolume = props => {
  const audioPlayer = props.target;
  const [currentVolume, setCurrentVolume] = useState(props.target.current.volume);


  const volumeBar = useRef();

  useEffect(() => {
    changeCurrentVolume();
  }, []);

  const updateVolumeBar = newVol => {
    audioPlayer.current.volume = Number(newVol);
    volumeBar.current.value = Number(newVol * 100);
    volumeBar.current.style.setProperty('--seek-before-volWidth', `${newVol * 100}%`);
  }

  const volDown = () => {
    setCurrentVolume((prevState) => {
      if(prevState > 0) {
        updateVolumeBar(prevState - 0.1);
        return prevState - 0.1
      }
      return prevState;
    });
  }

  const volUp = () => {
    setCurrentVolume((prevState) => {
      if(prevState < 1) {
        updateVolumeBar(prevState + 0.1);
        return prevState + 0.1
      }
      return prevState;
    });
  }

  const changeVolRange = () => {
    audioPlayer.current.volume = volumeBar.current.value / 100;
    changeCurrentVolume();
  }

  const changeCurrentVolume = () => {
    volumeBar.current.style.setProperty('--seek-before-volWidth', `${volumeBar.current.value}%`);
    //console.log(volumeBar.current.value);
    setCurrentVolume(volumeBar.current.value / 100);
  }

  return (
    <div className="volume">
      <div className="audioDown" onClick={volDown}>
       <VolumeDown  width="12px" height="19px" />
      </div>
      <div className="volProgress">
        <input type="range" className="volProgressBar" ref={volumeBar} defaultValue={currentVolume * 100} onChange={changeVolRange} />
      </div>
      <div className="audioUp" onClick={volUp}>
        <VolumeUp width="21px" height="19px" />
      </div>
    </div>
  );
}

export default AudioPlayerVolume;