export const YouTubeIcon = props => {
  return (
    <a href="https://www.youtube.com/@StuffNewZealand" target="_blank" rel="noreferrer">
    <svg version="1.1" className="youtubeIcon followIcon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 294.1 205.9" style={{width: props.width, height: props.height}}>
    <path className="YTItube" style={{fill: props.tubeColour}} d="M288.1,32.2c-3.4-12.6-13.3-22.5-25.9-25.9C239.1,0,147.1,0,147.1,0S55,0,32,6.1
      C19.6,9.4,9.4,19.6,6.1,32.2C0,55.2,0,103,0,103s0,48,6.1,70.7c3.4,12.6,13.3,22.5,25.9,25.9c23.3,6.3,115.1,6.3,115.1,6.3
      s92.1,0,115.1-6.1c12.6-3.4,22.5-13.3,25.9-25.9c6.1-23,6.1-70.7,6.1-70.7S294.4,55.2,288.1,32.2L288.1,32.2z"/>
    <path className="YTIarrow" style={{fill: props.arrowColour}} d="M117.7,58.9v88.2l76.6-44.1L117.7,58.9z"/>
  </svg>
  </a>
  )

}

