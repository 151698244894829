import React from "react";
import HostCard from "./HostCard";

import '../styles/Credits.css';

const Credits = () => {
  return (
    <div className="credits">
      <h3 className="centerHead">Hosted By</h3>
      <div className="hosts">
        <HostCard>
          <img src="img/host-imogen.jpg" alt="Imogen Wells" />
          <h4 className="leftHead">Imogen Wells</h4>
          <div className="bio">
            Imogen Wells loves to talk and thought what better way to make it a career than being a journalist? From her humble newsroom beginnings answering phones and serving coffee, to working as a political reporter at Parliament, to hosting Newsable, Imogen says her passion for the job still boils down to simply loving a good yarn.
          </div>
        </HostCard>
        <HostCard>
          <img src="img/host-jess.jpg" alt="Jess McCarthy" />
          <h4 className="leftHead">Jess McCarthy</h4>
          <div className="bio">
            Originally from Wellington, Jess is now an honorary Cantabrian who takes any negative talk about Otautahi as a personal slight. Unbearably nosey, nothing gives her a bigger kick than breaking news, and emails talking about free food in the office kitchen. She's worked as a journalist for TVNZ, Newstalk ZB and Sky News before finding her way to the Stuff newsroom.
          </div>
        </HostCard>
      </div>

      <div className="creditCard">
        <div className="creditType">
          <h4 className="leftHead">Scripted By</h4>
          <p>Imogen Wells</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Newsreader</h4>
          <p>Tom McKenzie</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Senior Producer</h4>
          <p>Philippa Tolley</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Senior Journalist and producer</h4>
          <p>Aaron Dahmen</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Mixing</h4>
          <p>Connor Scott, John Ropiha</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Executive Producer</h4>
          <p>Chris Reed</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Design</h4>
          <p>Ella Bates-Hermans</p>
        </div>
        <div className="creditType">
          <h4 className="leftHead">Development</h4>
          <p>John Harford, Felippe Rodrigues</p>
        </div>

      </div>

    </div>

  )
}

export default Credits;