export const InstagramLogo = props => {
  return (
    <a href="https://www.instagram.com/nzstuff/" target="_blank" rel="noreferrer">
      <svg version="1.1" className="instagram followIcon" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
    viewBox="0 0 256 256" style={{width: props.width, height: props.height}}>
      <path style={{fill: props.colour}} d="M128,23.1c34.2,0,38.2,0.1,51.7,0.7c12.5,0.6,19.3,2.7,23.8,4.4c6,2.3,10.2,5.1,14.7,9.6
        s7.3,8.7,9.6,14.7c1.8,4.5,3.8,11.3,4.4,23.8c0.6,13.5,0.7,17.5,0.7,51.7s-0.1,38.2-0.7,51.7c-0.6,12.5-2.7,19.3-4.4,23.8
        c-2.3,6-5.1,10.2-9.6,14.7c-4.5,4.5-8.7,7.3-14.7,9.6c-4.5,1.8-11.3,3.8-23.8,4.4c-13.5,0.6-17.5,0.7-51.7,0.7s-38.2-0.1-51.7-0.7
        c-12.5-0.6-19.3-2.7-23.8-4.4c-6-2.3-10.2-5.1-14.7-9.6c-4.5-4.5-7.3-8.7-9.6-14.7c-1.8-4.5-3.8-11.3-4.4-23.8
        c-0.6-13.5-0.7-17.5-0.7-51.7s0.1-38.2,0.7-51.7c0.6-12.5,2.7-19.3,4.4-23.8c2.3-6,5.1-10.2,9.6-14.7s8.7-7.3,14.7-9.6
        c4.5-1.8,11.3-3.8,23.8-4.4C89.8,23.2,93.8,23.1,128,23.1 M128,0C93.2,0,88.9,0.1,75.2,0.8C61.6,1.4,52.3,3.6,44.2,6.7
        c-8.4,3.3-15.6,7.6-22.7,14.8S10,35.7,6.7,44.2c-3.2,8.1-5.3,17.4-5.9,31.1C0.1,88.9,0,93.2,0,128s0.1,39.1,0.8,52.8
        c0.6,13.6,2.8,22.9,5.9,31.1c3.3,8.4,7.6,15.6,14.8,22.7c7.1,7.1,14.3,11.5,22.7,14.8c8.1,3.2,17.4,5.3,31.1,5.9
        c13.7,0.6,18,0.8,52.8,0.8s39.1-0.1,52.8-0.8c13.6-0.6,22.9-2.8,31.1-5.9c8.4-3.3,15.6-7.6,22.7-14.8c7.1-7.1,11.5-14.3,14.8-22.7
        c3.2-8.1,5.3-17.4,5.9-31.1c0.6-13.7,0.8-18,0.8-52.8s-0.1-39.1-0.8-52.8c-0.6-13.6-2.8-22.9-5.9-31.1c-3.3-8.4-7.6-15.6-14.8-22.7
        S220.3,10,211.8,6.7c-8.1-3.2-17.4-5.3-31.1-5.9C167.1,0.1,162.8,0,128,0z M128,62.3c-36.3,0-65.7,29.4-65.7,65.7
        s29.4,65.7,65.7,65.7s65.7-29.4,65.7-65.7S164.3,62.3,128,62.3z M128,170.7c-23.6,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7
        s42.7,19.1,42.7,42.7S151.6,170.7,128,170.7z M211.7,59.7c0,8.5-6.9,15.4-15.4,15.4S181,68.2,181,59.7s6.9-15.4,15.4-15.4
        S211.7,51.2,211.7,59.7z"/>
      </svg>
    </a>
  )
}
