import React, { useEffect } from "react";

import '../styles/AdSlot.css';

const AdSlot = props => {

  useEffect(()=> {
    window.googletag.cmd.push(function()  { window.googletag.display(props.adID); })
  });

  return (
    <div className='ads-new-wrap-container'>
      <p className='ads-new-wrap-container-p'>advertisement</p>
      <div id={props.adID}></div>
      <a href='https://advertise.stuff.co.nz/' target='_blank' rel='noopener noreferrer' className='ads-new-wrap-container-a'>Advertise with Stuff</a>
    </div>
  )

}

export default AdSlot;