import React from 'react';

import '../styles/NoEpisodes.css';

const NoEpisodes = () => {
  return (
    <div className='noEpisodes'>
      No episodes available
    </div>
  )
}

export default NoEpisodes;