import React from "react";
import AudioPlayer from "./AudioPlayer";

import '../styles/PreviousEpisodes.css';

const getDateStr = str => {
  const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const pubDateObj = new Date(str);
  return `${shortMonths[pubDateObj.getMonth()]} ${pubDateObj.getDate()}, ${pubDateObj.getFullYear()} `;
}

const bgClrs = ['bgClr1', 'bgClr2', 'bgClr3'];
const defaultPics = ['default1.jpg', 'default2.jpg', 'default3.jpg', 'default4.jpg', 'default5.jpg'];
// const defaultPics = ['imogen-jess-default1.jpg', 'imogen-jess-default2.jpg'];

let prevBg = '';

const getRandomBackground = (prev) => {
  let random = Math.floor(Math.random() * bgClrs.length);
  // prevent two colours repeating
  if(bgClrs[random] === prev) {
    random++
    if(random === bgClrs.length) {
      random = 0;
    }
  }

  prevBg = bgClrs[random];
  return bgClrs[random];
}

const getDefaultPic = () => {
  let random = Math.floor(Math.random() * defaultPics.length);
  return defaultPics[random];
}

const PreviousEpisodes = props => {
  // if there are no previous episode - display nothing
  if(!props.prevList || props.prevList.length < 1) {
    return;
  }
  return (
    <div className="previousEpisodes">
      {props.prevList.filter((ep, index) => index < 9).map((ep) => {
        //number of episodes returned is limited to 9
        //proper pagination should replace this
        //when it is available
        // console.log(ep);

        const bgClr = getRandomBackground(prevBg);

        // if no image uploaded for episode use default
        const imgUrl = ep.ep_image_file_url === 'None' ? `./img/${getDefaultPic()}`: ep.ep_image_file_url;

        // do we show images on prev episodes?
        const showImages = props.showImages;

        return (
          <div key={ep.ep_public_id} className={`episodeCard ${bgClr}`}>
            <div className="cardContent">
            <div className="epPic">
              {showImages ? <img src={imgUrl} alt={ep.ep_clean_title} /> : ''}

            </div>
            <div className={`epDate ${bgClr}`}>{getDateStr(ep.ep_pubdate)}</div>
            <div className="epTitle">{ep.ep_clean_title}</div>
            <div className="epSummary" dangerouslySetInnerHTML = {{__html: ep.ep_summary}}/>
            </div>

            {/* <audio controls>
              <source src={ep.ep_download_url} type="audio/mpeg" />
            </audio> */}
             <AudioPlayer src={ep.ep_download_url}  secs={ep.ep_duration} id={ep.ep_public_id}/>
          </div>
        );

      })}

    </div>
  );
}

export default PreviousEpisodes;