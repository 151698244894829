import React from "react";

import '../styles/PodcastPlatforms.css';

const PodcastPlatforms = () => {
  return (
  <div className="podcastPlatforms"><span>Also available on: </span><a href="https://podcasts.apple.com/us/podcast/newsable/id1673192369">&middot;&nbsp;Apple&nbsp;Podcasts</a><a href="https://open.spotify.com/show/1KD82iNSlI4X03HjjqEM1m">&middot;&nbsp;Spotify</a>
  </div>
  );
}

export default PodcastPlatforms;