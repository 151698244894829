export const PlayBtn = props => {
  return (
    <div className = {props.stylename}>
      <svg version="1.1" className="playArrow"  x="0px" y="0px"
      viewBox="0 0 16 21" style={{ width: props.width, height: props.height}}>
        <path style={{fill: '#000000'}} d="M16,10.5l-15.8,10l0-19.9L16,10.5z"/>
      </svg>
  </div>
  )
}
