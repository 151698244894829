import React, { useState } from "react";
import { StuffIcon } from "./logos/StuffIcon";
import { StuffLogo } from "./logos/StuffLogo";
// import ShareBar  from "./ShareBar";
// import FollowLinks from "./FollowLinks";
import ScrollEffect from './ScrollEffect';

import '../styles/Header.css';

const Header = () => {

  const [shouldHideHeader, setShouldHideHeader] = useState(false);


  const MINIMUM_SCROLL = 200;
  const TIMEOUT_DELAY = 400;

  ScrollEffect(callbackData => {
  const { previousScrollTop, currentScrollTop } = callbackData;
  const isScrolledDown = previousScrollTop < currentScrollTop;
  const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;


  setTimeout(() => {
    setShouldHideHeader(isScrolledDown && isMinimumScrolled);
  }, TIMEOUT_DELAY);

  });

  const hiddenStyle = shouldHideHeader ? 'hidden' : '';

  return (
    <div className={`Header ${hiddenStyle}`}>
      <div className="headerIcons">
      <StuffIcon width="21px" height="30px" colour="#ffffff" />
      <StuffLogo width="86px" height="30px" colour="#ffffff"/>
      </div>
      {/* <div className="allSocial">
      <FollowLinks />
      <ShareBar />
      </div> */}

    </div>
  )
}

export default Header;