import React from "react";

import '../styles/HeroImage.css';

const HeroImage = () => {
  return (
    <div className="heroImage">
      {/* <img src="img/imogen-front.jpg" alt="Imogen Wells" /> */}
      <img src="img/jess-imogen.jpg" alt="Jess McCarthy and Imogen Wells" />
    </div>
  )
}

export default HeroImage;