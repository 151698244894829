import React from "react";
import Loading from "./Loading";
import NoEpisodes from "./NoEpisodes";
import AudioPlayer from "./AudioPlayer";
import PodcastPlatforms from "./PodcastPlatforms";
// import PodcastIcons from "./PodcastIcons";
// import FollowLinks from "./FollowLinks";

import '../styles/LatestEp.css';

const LatestEp = props => {
  if(!props.ep) {
    // console.log('no data');
    return (
      <div className="podcast-latest">
        <Loading />;
      </div>
    )
  }

  // unsure if this will work as the podcast has a test episode
  // There are 23 keys per episode data. In theory less than that
  // should mean no episode?
  // or would no ep data be returned and be treated as above?
  if(Object.keys(props.ep).length < 23) {
    return (
      <div className="podcast-latest">
        <NoEpisodes />
      </div>
    )
  }

  //  console.log(Object.keys(props.ep).length);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const pubDateObj = new Date(props.ep.ep_pubdate);
  const dateStr = `${months[pubDateObj.getMonth()]} ${pubDateObj.getDate()}, ${pubDateObj.getFullYear()} `;

  return (
    <div className="podcast-latest">

      <div className="podcast-latest-info">
        <h4>latest episode</h4>

        <div className="podcast-latest-ep-title">
          <div className="podcast-latest-pubdate">{dateStr}</div>
          <h2>{props.ep.ep_clean_title}</h2>
        </div>
        <h3>{props.ep.ep_subtitle}</h3>
        <div dangerouslySetInnerHTML = {{__html: props.ep.ep_summary}}/>
        {/* <audio controls>
          <source src={props.ep.ep_download_url} type="audio/mpeg" />
        </audio> */}
        <AudioPlayer src={props.ep.ep_download_url}  secs={props.ep.ep_duration} id={props.ep.ep_public_id}/>
        {/* <PodcastIcons /> */}
        <PodcastPlatforms />
      </div>
    </div>

  )
};

export default LatestEp;