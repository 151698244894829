import React, { useState } from 'react';

import '../styles/Footer.css';

function Footer(){
    const [year] = useState(new Date().getFullYear())

       return (

        <div className="footercontainer">
            <span className="footercontainer--copy">&copy; {year} Stuff Limited</span>
        </div>

       );
}


export default Footer;