export const VertDots = props => {
  return (
    <svg version="1.1" className="vertdots" x="0px" y="0px"
      viewBox="0 0 10 20.7" style={{width: props.width, height: props.height}}>
    <path d="M7.5,3c0,1.4-1.1,2.5-2.5,2.5S2.5,4.4,2.5,3S3.6,0.4,5,0.4S7.5,1.6,7.5,3z M5,7.8c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5
	s2.5-1.1,2.5-2.5S6.4,7.8,5,7.8z M5,15.2c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S6.4,15.2,5,15.2z"/>
    </svg>
  )

}
