import React from "react";

import '../styles/HostCard.css';

const HostCard = props => {
  return (
    <div className="hostCard">
      {props.children}
    </div>
  )
}

export default HostCard;