export const TikTokIcon = props => {
  return (
    <a href="https://www.tiktok.com/@nzstuff" target="_blank" rel="noreferrer">
      <svg version="1.1" className="tiktok followIcon" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 650.1 750" style={{width: props.width, height: props.height}}>
      <path style={{fill: props.colour}} d="M569.1,160.4c-5.1-2.6-10-5.5-14.8-8.6c-13.9-9.2-26.6-20-37.9-32.2c-28.3-32.4-38.8-65.2-42.7-88.2
        h0.2C470.6,12.3,471.9,0,472.1,0H343.3v498.1c0,6.7,0,13.3-0.3,19.8c0,0.8-0.1,1.6-0.1,2.4c0,0.4,0,0.7-0.1,1.1c0,0.1,0,0.2,0,0.3
        c-2.8,36.3-23.4,68.8-55,86.8c-16.2,9.2-34.6,14.1-53.3,14.1c-60,0-108.7-48.9-108.7-109.4c0-60.4,48.6-109.4,108.7-109.4
        c11.4,0,22.7,1.8,33.5,5.3l0.2-131.2C201.8,269.5,135,289,83.8,332c-22.2,19.3-40.9,42.3-55.2,68c-5.4,9.4-26,47-28.4,108.2
        C-1.4,542.9,9,578.8,14,593.7v0.3c3.1,8.8,15.2,38.6,35,63.8c15.9,20.2,34.7,37.9,55.8,52.6v-0.3l0.3,0.3
        c62.4,42.4,131.5,39.6,131.5,39.6c12-0.5,52.1,0,97.6-21.6c50.5-23.9,79.2-59.6,79.2-59.6c18.4-21.3,33-45.6,43.2-71.8
        c11.7-30.6,15.5-67.4,15.5-82.1V250.8c1.6,0.9,22.4,14.7,22.4,14.7s30,19.2,76.8,31.7c33.6,8.9,78.8,10.8,78.8,10.8V180.1
        C634.2,181.8,602,176.8,569.1,160.4z"/>
    </svg>
    </a>
  );
}

