import React from "react";

import '../styles/Loading.css'

const Loading = () => {
  return (
    <div className = "loadingAnimation">
      <div className="loadingDots">
        <div className="ldots-bounce1"></div>
        <div className="ldots-bounce2"></div>
        <div className="ldots-bounce3"></div>
      </div>
    </div>

  )
}

export default Loading;