import React, { useState, useRef, useEffect } from "react";
import '../styles/AudioPlayer.css'
import { PlayBtn } from "./ui/PlayBtn";
import { PauseBtn } from "./ui/PauseBtn";
import { VertDots } from "./ui/VertDots";
import AudioPlayerVolume from "./AudioPlayerVolume";

const AudioPlayer = props => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showVolume, setShowVolume] = useState(false);


  // references
  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();
  // ep_duration value from api does not seem to account for ads
  // create var to hold reported value, then update from meta data
  // once the episode is ready
  const reportedDuration = useRef();


  useEffect(() => {
    let seconds = Math.floor(props.secs);
    // const seconds = props.secs === 0 ? Math.floor(audioPlayer.current.duration) : props.secs;

    // update reported duration once the episode is ready
    if(audioPlayer.current.readyState || audioPlayer.current.loadedmetadata){
      // console.log(audioPlayer.current.duration);
      seconds = Math.floor(audioPlayer.current.duration);
    }

    reportedDuration.current = seconds;

    setDuration(seconds);
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState, props.secs]);


  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (prevValue === false) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const toggleVolume = () => {
    const prevValue = showVolume;
    setShowVolume(!prevValue);
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    //update if current time is less than reportedDuration
    if(Math.ceil(audioPlayer.current.currentTime) <= Math.floor(reportedDuration.current)) {
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      // episode playback complete - reset player
      progressBar.current.value = 0;
      setCurrentTime(0);
      progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / reportedDuration.current * 100}%`);
      setIsPlaying(false);
    }
    // console.log(progressBar.current.value);
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / reportedDuration.current * 100}%`);
    setCurrentTime(progressBar.current.value);
  }

  // create link for chartable. Used for tracking
  const audioSrc = `https://chrt.fm/track/136497/traffic.megaphone.fm/${props.id}.mp3`;

  return (
    <div className="audioPlayer">
      {/* <audio ref={audioPlayer} src={props.src} preload="metadata"></audio> */}
      {/* preloading of metadata not permitted by Megaphone */}
      <audio ref={audioPlayer} src={audioSrc} preload="none"></audio>
      <button className="playPause" onClick={togglePlayPause}>
        { isPlaying ? <PauseBtn stylename="pause" width="16px" height="21px" /> : <PlayBtn stylename="play" width="16px" height="21px"/> }
      </button>


      {/* time */}
      <div className="time timePlayed"> {calculateTime(currentTime)}</div>

      {/* progress bar */}
      <div className='progress'>
        <input type="range" className="progressBar" defaultValue="0" ref={progressBar} onChange={changeRange}/>
      </div>

      {/* duration */}
      <div className="time timeDuration">
        {(duration && !isNaN(duration)) && calculateTime(duration)}
      </div>

      {/* volume */ }
      <div className="vertDots" onClick={toggleVolume}>
        <VertDots width="10px" height="21px"/>
        {/* {audioPlayer.current.volume} */}
      </div>

      { showVolume ? <AudioPlayerVolume target={audioPlayer}/> : ''}


    </div>
  )
}

export default AudioPlayer;