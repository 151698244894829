import '../styles/NewsableLogoAnimated.css';

export const NewsableLogoAnimated = () => {
  return (
    <svg version="1.1" id="animated_newsable"  x="0px" y="0px"
	 viewBox="0 0 3983 1808">
<path id="greenBubble" style={{fill:'#23E146'}} d="M3672.1,0h-3364c-93.7,0-166.9,75.9-166.9,169.5v706.2
	c0,104.6-56.7,199.8-141.2,248.6l2.8,5.7c104.5,0,220.2-22.6,276.8-84.8h2.9c29.3,50.7,84.1,84.8,146.8,84.8h3242.8
	c93.7,0,169.6-75.9,169.6-169.5v-791C3841.8,75.9,3765.8,0,3672.1,0z"/>
<g id="newsabletxt">
	<path style={{fill:'#161616'}} d="M423.7,960.5v-791h121.7l155.2,452h5.6v-452h113v791H705.8L542.4,514.2h-5.7v446.3H423.7z"/>
	<path style={{fill:'#161616'}} d="M3276.8,960.5v-791h282.5v113h-169.5v226h84.7v113h-84.7v226h169.5v113H3276.8z"/>
	<path style={{fill:'#161616'}} d="M875.7,960.5v-791h282.5v113H988.7v226h84.7v113h-84.7v226h169.5v113H875.7z"/>
	<path style={{fill:'#161616'}} d="M2937.8,960.5v-791h113v678h169.5v113H2937.8z"/>
	<path style={{fillRule: 'evenodd', clipRule:'evenodd', fill:'#161616'}} d="M2881.3,452c0,55.9-2.8,87.8-56.5,110.2v5.7
		c53.7,22.3,56.5,54.3,56.5,110.2v169.5c0,56.5-48,113-113.3,113h-225.7V570.7v-11.3V169.5h225.7c65.2,0,113.3,56.5,113.3,113V452z
		 M2711.8,508.5c-14.3,0.6-24.6,0.4-36,0.2c-6.2-0.1-12.8-0.2-20.5-0.2v-226h56.5c42.4,0,56.2,14.1,56.2,56.5v113
		C2768.1,508.5,2740.1,508.5,2711.8,508.5z M2711.8,621.5c-14.3-0.6-24.6-0.4-36-0.2h0c-6.2,0.1-12.7,0.2-20.4,0.2v226h56.5
		c42.4,0,56.2-14.1,56.2-56.5V678C2768.1,621.5,2740.1,621.5,2711.8,621.5z"/>
	<path style={{fillRule: 'evenodd', clipRule:'evenodd', fill:'#161616'}} d="M2146.9,282.5c0-62.4,50.6-113,113-113h113
		c62.4,0,113,50.6,113,113v435v130v113h-113V791h-113v169.5h-113v-113v-130V282.5z M2259.9,508.5V678h113V508.5V339
		c0-33.9-22.6-56.5-56.5-56.5c-33.9,0-56.5,22.6-56.5,56.5V508.5z"/>
	<path style={{fillRule: 'evenodd', clipRule:'evenodd', fill:'#161616'}} d="M1723.1,282.5c0-62.4,50.6-113,113-113h113
		c62.4,0,113,50.6,113,113V452h-113V339c0-33.9-22.6-56.5-56.5-56.5s-56.5,22.6-56.5,56.5v38.8c0,29.5,11.5,57.8,32.1,78.9
		L2030,622.5c20.6,21.1,32.1,49.4,32.1,78.9v146.1c0,62.4-50.6,113-113,113h-113c-62.4,0-113-50.6-113-113V678h113v113
		c0,33.9,22.6,56.5,56.5,56.5s56.5-22.6,56.5-56.5v-38.8c0-29.5-11.5-57.8-32.1-78.9l-161.8-165.8c-20.6-21.1-32.1-49.4-32.1-78.9
		V282.5z"/>
	<path style={{fill:'#161616'}} d="M1525.4,621.5l-28.2-452h-113l-28.2,452h-5.6l-50.8-452h-113l84.7,791h113l53.7-452h5.7
		l53.7,452h113l84.7-791h-113l-50.8,452H1525.4z"/>
</g>
<g id="pinkBubble">
	<path style={{fill:'#FF71EE'}} d="M2881.3,1271.2c0-46.8,37.9-84.8,84.7-84.8H3757c46.8,0,84.8,37.9,84.8,84.8v282.5
		c0,46.8-37.9,84.8-84.8,84.8h-790.9c-46.8,0-84.7-37.9-84.7-84.8V1271.2z"/>
	<path style={{fill: '#FF71EE'}} d="M3898.3,1582c0,62.4-50.6,113-113,113c-62.4,0-113-50.6-113-113s50.6-113,113-113
		C3847.7,1469,3898.3,1519.6,3898.3,1582z"/>
	<ellipse style={{fill: '#FF71EE'}} cx="3926.5" cy="1751.5" rx="56.5" ry="56.5"/>
</g>
<ellipse id="dot3" style={{fill:'#161616'}} cx="3615.8" cy="1412.5" rx="84.7" ry="84.8"/>
<ellipse id="dot2" style={{fill:'#161616'}} cx="3361.5" cy="1412.5" rx="84.7" ry="84.8"/>
<ellipse id="dot1" style={{fill:'#161616'}} cx="3107.3" cy="1412.5" rx="84.7" ry="84.8"/>
<g id="tagline">
	<path style={{fill: '#23E146'}} d="M484.3,1467.1l31.9-86.6h1.2l31.9,86.6h21.1l39.5-109.1h-19.5l-29.9,86.8h-1.1l-32.9-86.8h-18.8
		l-32.7,86.8h-1.1l-29.9-86.8h-20.5l39.7,109.1H484.3z"/>
	<path style={{fill: '#23E146'}} d="M684.8,1469c35.5,0,65.1-19.5,65.1-56.5s-29.6-56.5-65.1-56.5c-35.5,0-65.1,19.5-65.1,56.5
		S649.3,1469,684.8,1469z M684.8,1453c-27.1,0-46.2-13.7-46.2-40.5s19.1-40.5,46.2-40.5c27.3,0,46.3,13.7,46.3,40.5
		S712,1453,684.8,1453z"/>
	<path style={{fill: '#23E146'}} d="M862.7,1438.2c-3.3-14.3-9.9-19.4-19.1-22.2v-1.3c13.8-3.3,23.2-12.4,23.2-27.3
		c0-19.4-14.1-29.5-43.9-29.5h-53v109.1h18.8v-44.3h28.8c19.1,0,23.9,6.8,26.8,19.5c2.2,8.8,3.6,15.9,6.7,24.8h20.1
		C867.1,1456,865.7,1450.4,862.7,1438.2z M788.8,1408.1v-34.5H822c19.8,0,26.3,6.2,26.3,16.4c0,11.6-8.2,18.1-27.3,18.1H788.8z"/>
	<path style={{fill: '#23E146'}} d="M946.2,1467.1v-93.1h47.9v-16H879.4v16h48v93.1H946.2z"/>
	<path style={{fill: '#23E146'}} d="M1101.7,1403.9h-73v-45.9H1010v109.1h18.7v-47.6h73v47.6h18.8v-109.1h-18.8V1403.9z"/>
	<path style={{fill: '#23E146'}} d="M1245.9,1467.1v-93.1h47.9v-16h-114.7v16h48.1v93.1H1245.9z"/>
	<path style={{fill: '#23E146'}} d="M1413.9,1467.1l-53.9-109.1h-21.2l-53.8,109.1h19.4l12.9-26.4h64.2l12.9,26.4H1413.9z
		 M1348.7,1375.9h1.1l24.5,50.2h-49.9L1348.7,1375.9z"/>
	<path style={{fill: '#23E146'}} d="M1517.1,1467.1v-16h-70.4v-93.1H1428v109.1H1517.1z"/>
	<path style={{fill: '#23E146'}} d="M1585,1404.8l58-46.9h-26l-62.3,50.6v-50.6h-18.8v109.1h18.8v-38.3l15.5-12.5l48.4,50.8h25.3
		L1585,1404.8z"/>
	<path style={{fill: '#23E146'}} d="M1658,1467.1h18.8v-109.1H1658V1467.1z"/>
	<path style={{fill: '#23E146'}} d="M1719.1,1467.1v-83.2h1.1l73.9,83.2h17.7v-109.1h-17.7v81.2h-1.1l-72.7-81.2h-18.9v109.1H1719.1z"/>
	<path style={{fill: '#23E146'}} d="M1832,1412.5c0,36,29.4,56.5,63.1,56.5c20.9,0,36.9-6.7,46.6-18.2h1.1l1.7,16.3h13.3v-55.5h-67.9v14
		h49.9c-2.3,18.7-18.9,27.8-42.5,27.8c-26.8,0-46.5-14.8-46.5-40.5c0-26.2,19.7-40.9,46.7-40.9c20.9,0,36.4,7.7,41.4,24.4l18.3-3.9
		c-6.8-22.6-28.5-36.5-59.5-36.5C1861.7,1356,1832,1376.5,1832,1412.5z"/>
	<path style={{fill: '#23E146'}} d="M2140.1,1467.1l-53.9-109.1H2065l-53.8,109.1h19.4l12.9-26.4h64.2l12.9,26.4H2140.1z M2074.9,1375.9
		h1.1l24.5,50.2h-49.9L2074.9,1375.9z"/>
	<path style={{fill: '#23E146'}} d="M2233.2,1409.9c11.2-4.1,17.5-12,17.5-24.4c0-17.8-12.4-27.5-37.7-27.5h-58.9v109.1h61.8
		c29.1,0,41.8-11.6,41.8-30.3c0-13.8-8.2-22.6-24.6-25.7V1409.9z M2211.7,1372.9c15.8,0,21.1,6.2,21.1,15.8
		c0,9.6-6.5,15.8-21.2,15.8h-38.6v-31.6H2211.7z M2172.9,1452.1v-33.5h41.9c16.6,0,24.3,6.7,24.3,16.9c0,10.1-6.1,16.6-23.9,16.6
		H2172.9z"/>
	<path style={{fill: '#23E146'}} d="M2336.9,1469c35.5,0,65.1-19.5,65.1-56.5s-29.6-56.5-65.1-56.5s-65.1,19.5-65.1,56.5
		S2301.4,1469,2336.9,1469z M2336.9,1453c-27.1,0-46.2-13.7-46.2-40.5s19.1-40.5,46.2-40.5c27.3,0,46.3,13.7,46.3,40.5
		S2364.2,1453,2336.9,1453z"/>
	<path style={{fill: '#23E146'}} d="M2473.6,1469c25.7,0,52.7-9.9,52.7-45.4v-65.6h-18.8v65c0,19.9-13,30.1-33.9,30.1
		c-20.8,0-34.1-10.2-34.1-30.3v-64.8h-18.9v65.6C2420.6,1458.9,2447.6,1469,2473.6,1469z"/>
	<path style={{fill: '#23E146'}} d="M2607.4,1467.1v-93.1h47.9v-16h-114.7v16h48v93.1H2607.4z"/>
</g>
</svg>
  )
}