import React from "react";

import '../styles/PreviousBanner.css';



const PreviousBanner = props => {

  // scroll previous episodes into view on button click
  const scrollEps = (e) => {
    const target = e.target.parentElement.previousElementSibling;
    target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  // if there are no previous episodes - display nothing
  if(!props.prevList || props.prevList.length < 1) {
    return
  }
  return (
    <div className="previousBanner">
      <button className="previousBtn" onClick={scrollEps}>Previous Episodes <svg version="1.1" className="btnarrow" x="0px" y="0px"
	 viewBox="0 0 15 29" style={{enableBackground: 'new 0 0 15 29'}}>
<path style={{fill: '#000000'}} d="M6.9,28.3c0.4,0.4,1,0.4,1.4,0l6.4-6.4c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.7,5.7l-5.7-5.7
	c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L6.9,28.3z M6.6,0.8v26.8h2V0.8H6.6z"/>
</svg></button>
    </div>
  );
}

export default PreviousBanner;