import React from "react";

import '../styles/PodcastCard.css';

const PodcastCard = props => {
  return (
    <div className="podcastCard">
      {props.children}
    </div>
  )
}

export default PodcastCard;