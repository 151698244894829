export const VolumeDown = props => {
  return (
    <svg version="1.1" className="voldown" x="0px" y="0px"
	 viewBox="0 0 12 18.7" style={{width:props.width, height: props.height}}>
<path d="M-0.1,6.6c0.2-0.5,0.5-0.7,1-0.7c1,0,2,0,3,0c0.2,0,0.3,0,0.4-0.2c0,0,0.1-0.1,0.1-0.1C5,5,5.6,4.4,6.2,3.8
	C6.9,3,7.7,2.2,8.4,1.5c0.4-0.4,0.7-0.5,1-0.3c0.3,0.1,0.4,0.4,0.5,0.7c0,0.1,0,0.2,0,0.3c0,4.8,0,9.6,0,14.4c0,0.5-0.2,0.8-0.7,1
	c-0.2,0.1-0.5,0-0.6-0.2c-0.3-0.3-0.6-0.6-0.9-0.9c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.3-1.8-1.9c-0.5-0.5-1-1-1.5-1.5
	c-0.1-0.1-0.2-0.1-0.3-0.1c-1,0-2,0-3,0c-0.6,0-0.8-0.2-1-0.7C-0.1,10.3-0.1,8.4-0.1,6.6z"/>
</svg>

  )
}
