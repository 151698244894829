import React from "react";
import { InstagramLogo } from "./logos/InstagramLogo";
import { TikTokIcon } from "./logos/TikTokIcon";
import { YouTubeIcon } from "./logos/YouTubeIcon";
// import ShareBar from "./ShareBar";

import '../styles/AllFollow.css';

const AllFollow = () => {
  return (
    <div className="allFollow">
      <div className="followText">Follow us on</div>
      <div className="followIcons">
        <InstagramLogo width="22px" height="22px" colour="#000000" />
        <TikTokIcon width="19px" height="22px" colour="#000000" />
        <YouTubeIcon width="31px" height="22px" tubeColour="#000000" arrowColour="#ffffff" />
        {/* <ShareBar /> */}
      </div>

    </div>
  )
}

export default AllFollow;