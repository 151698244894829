//options for displaying previous episodes
// props.setImages originates in App.js

import React from "react";

import '../styles/PreviousOptions.css';

const PreviousOptions = props => {
  if(!props.prevList || props.prevList.length < 1) {
    return
  }
  const btnClass = props.showImages ? 'imageHideBtn' : 'imageShowBtn';
  return (
    <div className="previousOptions">
      <div className="pagination">
        {/* to come */}
      </div>
      <div className="imageOption" onClick={props.setImages}>
          <span>images</span>
          <div className={`imageOptionBtn ${btnClass}`} ></div>
      </div>
    </div>
  );
}

export default PreviousOptions;