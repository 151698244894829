import React from "react";
import { NewsableLogoAnimated } from "./NewsableLogoAnimated";

import "../styles/Splash.css";

const Splash = () => {
  return (
    <div className="splashWrapper">
      <div className="newsableSplash">
        <NewsableLogoAnimated />
      </div>
      <div className="badge">
        <p className="badge__award">
          INMA Global
          <br />
          Media Awards 2024
        </p>
        <div className="badge__title">
          Best New
          <br />
          Audio Product
        </div>
      </div>
    </div>
  );
};

export default Splash;
