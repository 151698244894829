export const StuffIcon = props => {
  return (
    <a href="https://stuff.co.nz" className="stuffIconHeader" target="_blank" rel="noreferrer">
      <svg version="1.1" id="s-mono" x="0px" y="0px"
        viewBox="0 0 230.9 305.7" style={{width: props.width, height: props.height}}>
      <g>
        <path style={{fill: props.colour}} d="M105.4,118.7c-24.1-4.8-33-14.9-33-31.4c0-18.5,15.7-29.8,42.2-29.8c26.5,0,43.8,12.5,46.7,36.6
          h23.3c-3.6-39.8-34.2-56.7-70-56.7c-36.6,0-65.6,15.3-65.6,51.1c0,29,18.5,44.6,51.1,51.5l42.6,8.8c42.6,8.8,64.8,27.4,64.8,64.8
          c0,44.2-33.4,72-91.3,72c-43.8,0-77.2-17.3-88.9-51.1l8-9.7c-0.8-3.6-1.6-7.6-2-11.3H0c2.8,59.5,47.9,92.1,116.2,92.1
          c68.8,0,114.6-31.8,114.6-92.5c0-49.5-31-75.6-81.7-85.7L105.4,118.7z"/>
        <path style={{fill: props.colour}} d="M129.9,187c25.3,5.2,35,14.1,35,29.8c0,21.3-16.9,31.4-47.1,31.4c-32.2,0-49.1-11.3-51.9-34.6H42.6
          c4.8,40.2,34.6,54.7,74.8,54.7c41.4,0,70.8-15.3,70.8-52.7c0-31-20.5-43.4-51.9-49.9l-42.6-8.8c-44.6-9.3-64-31.4-64-68
          c0-42.2,28.2-68.8,84.9-68.8c44.6,0,75.2,18.5,85.7,53.1l-8,9.7c0.8,3.6,1.6,7.6,2,11.3h33C222.8,38.2,184.2,0,114.6,0
          C51.9,0,6.4,29,6.4,88.9c0,52.7,31.8,79.2,82.9,89.7L129.9,187z"/>
      </g>
      </svg>
    </a>
  )
}
