import React from "react";

import '../styles/OtherPodcasts.css';
import PodcastCard from "./PodcastCard";

const OtherPodcasts = () => {
  return (
    <div className="otherPodcasts">
      <h3 className="centerHead"><a href="https://interactives.stuff.co.nz/podcasts/">More podcasts from Stuff</a></h3>
      <div className="otherPodsList">
        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/true-story-podcast/">
            <img src="https://static3.stuff.co.nz/true-story1x1-628d6929.jpg" alt="True Story logo"></img>
            <div className="otherPodTitle">True New Zealand stories</div>
            <div className="otherPodAuthor">Eugene Bingham, Adam Dudding</div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/2022/the-commune-podcast-centrepoint/">
            <img src="https://static3.stuff.co.nz/the-commune-show-cover-054cb75e.jpg" alt="The Commune logo"></img>
            <div className="otherPodTitle">Sex, drugs - and a guru called Bert</div>
            <div className="otherPodAuthor">Adam Dudding, Eugene Bingham</div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/the-long-read-podcast/">
            <img src="https://static3.stuff.co.nz/tlr-cover-2fca3c64.jpg" alt="The Long Read logo"></img>
            <div className="otherPodTitle">The Long Read from Stuff</div>
            <div className="otherPodAuthor">Michael Wright</div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/2022/simon-bridges-generally-famous-podcast/">
            <img src="https://static3.stuff.co.nz/1-1-cover-v2-1de7ae7b.jpg" alt="Generally Famous logo"></img>
            <div className="otherPodTitle">Simon Bridges Generally Famous</div>
            <div className="otherPodAuthor">Simon Bridges</div>
          </a>
        </PodcastCard>

      </div>
    </div>

  )
}

export default OtherPodcasts;