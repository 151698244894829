export const PauseBtn = props => {
  return (
    <div className={props.stylename}>
      <svg version="1.1" className="pauseIcon"  x="0px" y="0px"
      viewBox="0 0 16 21"style={{ width: props.width, height: props.height}}>
    <path style={{fill: '#000000'}} d="M6.3,20.2H2.2V0.5h4.1V20.2z M13.5,0.5H9.4v19.6h4.1V0.5z"/>
    </svg>
  </div>
  )

}