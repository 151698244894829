export const StuffLogo = props => {
  return (
    <a href="https://stuff.co.nz" className="stuffLogoHeader" target="_blank" rel="noreferrer">
  <svg version="1.1" className="stuffLogo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 150.6 52.2" style={{width: props.width, height: props.height}} >
  <polygon style={{fill: props.colour}} points="142.8,51.5 142.8,26.2 150.6,26.2 150.6,22.6 138.8,22.6 138.8,51.5 "/>
  <polygon style={{fill: props.colour}} points="117.3,51.5 121.3,51.5 121.3,26.2 131.7,26.2 131.7,51.5 135.7,51.5 135.7,22.6 117.3,22.6
    "/>
  <polygon style={{fill: props.colour}} points="106.6,21.2 104.7,21.2 104.7,26.2 110.2,26.2 110.2,51.5 114.2,51.5 114.2,22.6 108,22.6 "/>
  <g>
    <path style={{fill: props.colour}} d="M138.8,19.6h8.4l1.4,1.4h1.9v-5h-7.8v-2.5c0-2.1,0.6-2.7,2.8-2.7h4.9V7.2h-5.2c-4.5,0-6.5,2-6.5,6.3
      v6.1H138.8z"/>
    <path style={{fill: props.colour}} d="M144.7,0.7c-7,0-13.1,3.5-13.1,12.2v3.2h-10.4v-2.5c0-2.1,0.6-2.7,2.8-2.7h4.6V7.2h-4.9
      c-4.5,0-6.5,2-6.5,6.3v6.2h18.3v-6.5c0-7,4.7-8.9,9.1-8.9h5.8V0.7H144.7z"/>
    <path style={{fill: props.colour}} d="M114.2,13.1c0-7,4.7-8.9,9.1-8.9h5.4V0.7h-5.5c-7,0-13.1,3.5-13.1,12.2v3.2h-5.5v3.6h9.5L114.2,13.1
      L114.2,13.1z"/>
    <path style={{fill: props.colour}} d="M85.3,45.7c5.8,0,9.1-3.4,9.1-9V19.4l1.4-1.4v-1.9h-5.4v20.7c0,3.5-1.6,5.3-5.1,5.3
      c-3.4,0-5.2-1.8-5.2-5.3V16.1h-4v20.6C76.2,42.2,79.5,45.7,85.3,45.7"/>
    <path style={{fill: props.colour}} d="M97.5,36.6c0,7.6-4.5,12-12.2,12c-7.8,0-12.3-4.4-12.3-12V19.4l1.4-1.4v-1.9H69v20.4
      c0,10.6,6.6,15.7,16.3,15.7c9.6,0,16.2-5.1,16.2-15.7V16.1h-4V36.6z"/>
    <path style={{fill: props.colour}} d="M52.5,39.2c0,4.6,2.1,5.8,6.2,5.8h7.2v-3.6H59c-2,0-2.5-0.8-2.5-2.7V26.2h9.4v-3.6H52.5V39.2z"/>
    <path style={{fill: props.colour}} d="M49.3,22.6h-6.1l-1.4-1.4h-1.9v5h5.5v13.9c0,8.9,5.4,11.4,12.1,11.4h8.4v-3.6h-7.7
      c-4.9,0-8.9-1.5-8.9-8.4C49.3,39.5,49.3,22.6,49.3,22.6z"/>
  </g>
  <polygon style={{fill: props.colour}} points="56.5,6.3 52.5,6.3 52.5,19.6 62.5,19.6 64,21.1 65.9,21.1 65.9,16.1 56.5,16.1 "/>
  <polygon style={{fill: props.colour}} points="45.3,6.3 45.3,16.1 39.8,16.1 39.8,19.6 49.3,19.6 49.3,6.3 "/>
  <g>
    <path style={{fill: props.colour}} d="M18,20.3c-4.1-0.8-5.6-2.5-5.6-5.4c0-3.2,2.7-5.1,7.2-5.1s7.5,2.1,8,6.3h4c-0.6-6.8-5.8-9.7-12-9.7
      C13.3,6.4,8.4,9,8.4,15.1c0,4.9,3.2,7.6,8.7,8.8l7.3,1.5c7.3,1.5,11.1,4.7,11.1,11.1c0,7.6-5.7,12.3-15.6,12.3
      c-7.5,0-13.2-3-15.2-8.7L6,38.4c-0.1-0.6-0.3-1.3-0.3-1.9H0c0.5,10.2,8.2,15.7,19.9,15.7s19.6-5.4,19.6-15.8
      c0-8.4-5.3-12.9-13.9-14.6L18,20.3z"/>
    <path style={{fill: props.colour}} d="M22.2,31.9c4.3,0.9,6,2.4,6,5.1c0,3.6-2.9,5.4-8,5.4c-5.5,0-8.4-1.9-8.9-5.9h-4
      c0.8,6.9,5.9,9.3,12.8,9.3c7.1,0,12.1-2.6,12.1-9c0-5.3-3.5-7.4-8.9-8.5L16,26.8C8.4,25.2,5.1,21.4,5.1,15.2
      C5.1,8,9.9,3.4,19.6,3.4c7.6,0,12.8,3.2,14.6,9.1l-1.4,1.6c0.1,0.6,0.3,1.3,0.3,1.9h5.6C38.1,6.5,31.5,0,19.6,0
      C8.9,0,1.1,4.9,1.1,15.2c0,9,5.4,13.5,14.2,15.3L22.2,31.9z"/>
  </g>
  </svg>
</a>
  )
}